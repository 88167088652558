// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-businesses-jsx": () => import("./../../../src/pages/businesses.jsx" /* webpackChunkName: "component---src-pages-businesses-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-request-quote-jsx": () => import("./../../../src/pages/request-quote.jsx" /* webpackChunkName: "component---src-pages-request-quote-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-managed-jsx": () => import("./../../../src/pages/services/managed.jsx" /* webpackChunkName: "component---src-pages-services-managed-jsx" */),
  "component---src-pages-services-managed-web-hosting-jsx": () => import("./../../../src/pages/services/managed-web-hosting.jsx" /* webpackChunkName: "component---src-pages-services-managed-web-hosting-jsx" */),
  "component---src-pages-services-support-maintenance-jsx": () => import("./../../../src/pages/services/support-maintenance.jsx" /* webpackChunkName: "component---src-pages-services-support-maintenance-jsx" */),
  "component---src-pages-services-web-applications-jsx": () => import("./../../../src/pages/services/web-applications.jsx" /* webpackChunkName: "component---src-pages-services-web-applications-jsx" */),
  "component---src-pages-services-web-design-jsx": () => import("./../../../src/pages/services/web-design.jsx" /* webpackChunkName: "component---src-pages-services-web-design-jsx" */),
  "component---src-pages-services-web-development-jsx": () => import("./../../../src/pages/services/web-development.jsx" /* webpackChunkName: "component---src-pages-services-web-development-jsx" */),
  "component---src-pages-services-website-packages-jsx": () => import("./../../../src/pages/services/website-packages.jsx" /* webpackChunkName: "component---src-pages-services-website-packages-jsx" */),
  "component---src-pages-services-websites-jsx": () => import("./../../../src/pages/services/websites.jsx" /* webpackChunkName: "component---src-pages-services-websites-jsx" */),
  "component---src-pages-services-white-label-websites-jsx": () => import("./../../../src/pages/services/white-label-websites.jsx" /* webpackChunkName: "component---src-pages-services-white-label-websites-jsx" */),
  "component---src-pages-services-wordpress-development-jsx": () => import("./../../../src/pages/services/wordpress-development.jsx" /* webpackChunkName: "component---src-pages-services-wordpress-development-jsx" */),
  "component---src-pages-style-guide-jsx": () => import("./../../../src/pages/style-guide.jsx" /* webpackChunkName: "component---src-pages-style-guide-jsx" */),
  "component---src-pages-why-us-jsx": () => import("./../../../src/pages/why-us.jsx" /* webpackChunkName: "component---src-pages-why-us-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

